
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.contact-item {
  transform: translateX(-100%);
  transition: all 1s;
  opacity: 0;
  filter: blur(0.5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $dark-dark;
  padding: 1.6rem;
  border-radius: 1rem;
  border: 0.1rem solid rgba($accent-light, 0.1);

  &--visible {
    transform: translateX(0);
    opacity: 1;
    filter: blur(0);
  }

  &__icon {
    margin-bottom: 1.6rem;
    height: 2.4rem;
  }

  &__title {
    color: $accent;
    text-align: center;

    @include body;

    @include mq-medium {
      @include heading-2;
    }
  }

  &__content {
    margin-top: 0.8rem;
    align-self: stretch;

    @include mq-medium {
      margin-top: 1.6rem;

      @include heading-3;
    }

    @include body;

    color: $accent;
    text-align: center;
  }
}
