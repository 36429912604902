
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.prices {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;

  @include mq-medium {
    margin-top: 12rem;
  }

  &__title {
    @include heading-2;
    @include gradient-color;

    margin-bottom: 4rem;
    font-weight: bold;
    text-align: center;

    @include mq-medium {
      @include heading-0;

      margin-bottom: 8rem;
    }
  }

  &__text {
    margin-top: 2rem;
    text-align: center;

    @include gradient-color;

    font-family: Roboto;
    font-size: 1.2rem;
    line-height: 2.2rem;  }
}
