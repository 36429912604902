
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.confidentiality {
  padding: 0 3rem;

  @include mq-medium {
    padding: 0 8rem;
  }

  &__big-title {
    @include gradient-color;
    @include heading-3;

    font-style: italic;
    font-weight: bold;
    text-align: center;
    margin: 6rem 0 4rem;

    @include mq-medium {
      @include heading-1;

      margin: 12rem 0 10rem;
    }
  }

  &__subtitle {
    @include heading-4;

    font-weight: bold;
    margin-bottom: 2rem;
    color: $accent;

    @include mq-medium {
      @include heading-2;

      margin-bottom: 5rem;
    }

    &--margin-top {
      margin-top: 4rem;
    }
  }

  &__ul-list {
    list-style-position: outside;
    list-style-type: circle;
    padding-left: 2rem;

    @include mq-medium {
      padding: 4rem;
    }
  }

  &__ol-list {
    list-style: auto;
  }

  &__description {
    color: $accent;
    margin-top: 1.5rem;
    text-align: justify;
    line-height: 2rem;

    @include heading-5;

    a {
      color: $accent;
    }
  }

  &__long-link {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  &__table-wrapper {
    width: 100%;
    overflow: scroll;
  }

  &__table {
    border: 1px solid $accent;
    width: 80%;
    margin: 2rem auto;

    @include mq-medium {
      margin: 4rem auto;
    }

    th,
    td {
      border: 1px solid $accent;
    }
  }

  &__table-title {
    text-align: center;
    color: $accent;
    padding: 1rem;

    @include heading-5;

    @include mq-medium {
      line-height: 2rem;
      padding: 2rem;

      @include heading-4;
    }
  }

  &__table-text {
    padding: 1rem;
    color: $accent;
    vertical-align: middle;

    @include heading-5;

    @include mq-medium {
      line-height: 2rem;
      padding: 2rem;

      @include heading-4;
    }
  }

  &__table-text-wrap {
    white-space: nowrap;
  }
}
