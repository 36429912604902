
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.accordion {
  background: $dark-dark;

  &:nth-child(odd) {
    transform: translateX(100%);
  }

  &:nth-child(even) {
    transform: translateX(-100%);
  }

  opacity: 0;
  filter: blur(0.5rem);
  transition: all 1s;

  &--visible {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0) !important;
  }

  border-left: 0.1rem solid rgba($accent-light, 0.1);
  border-right: 0.1rem solid rgba($accent-light, 0.1);

  &:first-child {
    border-radius: 1rem 1rem 0 0;
    border-top: 0.1rem solid rgba($accent-light, 0.1);
  }

  &:last-child {
    border-bottom: 0.1rem solid rgba($accent-light, 0.1);
    border-radius: 0 0  1rem 1rem;
  }

  &:not(:last-child) {
    border-bottom: 0.1rem solid rgba($accent-light, 0.1);
  }

  &__title {
    @include heading-4;
    @include gradient-color;

    width: 100%;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 0.8rem;

    @include mq-medium {
      @include heading-3;

      padding: 1.6rem;
    }
  }

  &__arrow {
    margin-left: auto;
    transition: transform 0.5s;
    width: 1.6rem;

    @include mq-medium {
      width: 2.4rem;
    }

    &--rotated {
      transform: rotate(180deg);
    }
  }

  &__list-item {
    @include heading-4;
    @include gradient-color;

    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 1.6rem;

    @include mq-medium {
      @include heading-3;

      padding: 1.6rem 3.2rem;
    }
  }

  &__list-name {
    margin-right: 0.8rem;
  }

  &__list-price {
    margin-left: auto;
    white-space: nowrap;
    align-self: flex-start;
  }
}
