
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.about-us-section {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include mq-medium {
    flex-direction: row;
  }

  &:not(:last-child) {
    margin-bottom: 3.2rem;
  }

  &--reversed {
    @include mq-medium {
      flex-direction: row-reverse;
    }
  }

  &__paragraphs {
    transform: translateX(100%);
    transition: all 1s;
    opacity: 0;
    filter: blur(0.5rem);

    &--reversed {
      transform: translateX(-100%);
    }

    &--visible {
      opacity: 1;
      filter: blur(0);
      transform: translateX(0) !important;
    }
  }

  &__image {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    transform: translateX(-100%);
    transition: all 1s;
    opacity: 0;
    filter: blur(0.5rem);
    margin-bottom: 1.6rem;

    @include mq-medium {
      margin-bottom: 0;
      margin-right: 3.2rem;
    }

    &--reversed {
      @include mq-medium {
        margin-right: 0;
        margin-left: 3.2rem;
      }

      transform: translateX(100%);
    }

    &--visible {
      opacity: 1;
      filter: blur(0);
      transform: translateX(0) !important;
    }
  }

  &__paragraph {
    color: $accent;
    text-align: justify;
    line-height: 2rem;

    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }

    @include body;
  }
}
