$primary-dark: #675a40;
$primary: #7a6b4c;
$primary-light: #7a6b4c;
$secondary-dark: #ad915d;
$secondary: #bd9d62;
$secondary-light: #ccac6e;
$accent-dark: #decea2;
$accent: #edd9a1;
$accent-light: #faedc8;
$dark: #04070a;
$dark-dark: #000;
