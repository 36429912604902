
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.contact {
  margin-top: 6rem;

  @include mq-medium {
    margin-top: 12rem;
  }

  &__title {
    @include gradient-color;
    @include heading-2;

    font-weight: bold;
    text-align: center;

    @include mq-medium {
      @include heading-0;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__items {
    margin-top: 4rem;
    display: grid;
    grid-gap: 3.2rem 0;
    align-items: stretch;
    justify-content: center;

    @include mq-medium {
      grid-gap: 0 1.6rem;
    }

    @include mq-medium {
      grid-template-columns: repeat(3, 1fr);
      margin-top: 8rem;
    }
  }

  &__link {
    text-decoration: none;

    &--phone {
      margin-bottom: 2.4rem;
      display: block;
    }

    &--address {
      display: flex;
    }
  }

  &__table {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    justify-content: space-between;
    align-items: center;
    grid-row-gap: 1.6rem;
  }

  &__delimiter {
    width: 0.1rem;
    height: 100%;
    background-color: rgba($accent-light, 0.1);
  }

  &__address-item {
    margin-bottom: 0.6rem;
    display: block;
  }

  &__text {
    margin-top: 2rem;
    text-align: center;

    @include gradient-color;
    @include heading-4;
  }
}
