
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.services {
  margin-top: 6rem;

  @include mq-medium {
    margin-top: 12rem;
  }

  &__title {
    @include heading-2;
    @include gradient-color;

    margin-bottom: 4rem;
    font-weight: bold;
    text-align: center;

    @include mq-medium {
      @include heading-0;

      margin-bottom: 8rem;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(14.8rem, 14.8rem));
    gap: 2rem 0.8rem;
    justify-items: center;
    align-items: center;
    justify-content: space-around;

    @include mq-medium {
      grid-template-columns: repeat(auto-fill, minmax(29.6rem, 29.6rem));
    }
  }
}
