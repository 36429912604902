
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.terms-and-conditions {
  padding: 0 3rem;

  @include mq-medium {
    padding: 0 8rem;
  }

  &__title {
    @include gradient-color;
    @include heading-3;

    font-style: italic;
    font-weight: bold;
    text-align: center;
    margin: 6rem 0 4rem;

    @include mq-medium {
      @include heading-1;

      margin: 12rem 0 10rem;
    }
  }

  &__subtitle {
    @include heading-4;

    font-weight: bold;
    margin-bottom: 2rem;
    color: $accent;

    @include mq-medium {
      @include heading-2;

      margin-bottom: 5rem;
    }

    &--margin-top {
      margin-top: 4rem;
    }
  }

  &__ol-list {
    list-style: auto;
  }

  &__ul-list {
    list-style-position: outside;
    list-style-type: circle;
    padding-left: 2rem;

    @include mq-medium {
      padding: 4rem;
    }
  }

  &__description {
    color: $accent;
    margin-top: 1.5rem;
    text-align: justify;
    line-height: 2rem;

    @include heading-5;

    a {
      color: $accent;
    }
  }
}
