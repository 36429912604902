
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.service-card {
  opacity: 0;
  transform: translateX(-100%);
  filter: blur(0.5rem);
  transition: all 1s;

  &--visible {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
  }

  &__wrapper {
    border-radius: 1rem;
    border: 0.1rem solid rgba($accent-light, 0.1);
    width: 14.8rem;
    height: 14.8rem;
    background-color: $dark-dark;
    transition: all 0.5s;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    @include mq-medium {
      transform: none !important;
      width: 29.6rem;
      height: 29.6rem;
    }
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    transform: translateY(0);
    transition: transform 0.5s;
  }

  &__logo {
    height: 3rem;
    margin-bottom: 1.6rem;

    @include mq-medium {
      height: 6rem;
    }
  }

  &__title {
    @include body;
    @include gradient-color;

    font-size: 1.2rem;
    text-align: center;
    font-weight: 700;

    @include mq-medium {
      font-size: 2.4rem;
      line-height: 3rem;
    }
  }

  &__description-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    transform: translateY(100%);
    transition: transform 0.5s;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__description {
    @include body;

    text-align: center;
    color: $accent;
    font-size: 0.7rem;

    @include mq-medium {
      font-size: 1.5rem;
    }
  }

  &--hovered {
    .service-card {
      &__main {
        transform: translateY(-100%);
      }

      &__description-wrapper {
        transform: translateY(0);
      }
    }
  }
}
