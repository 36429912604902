
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.app {
  &__wrapper {
    overflow: hidden;
  }

  &__main {
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 0.8rem;

    @include mq-medium {
      padding: 0 1.6rem;
    }
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.PGEYpBmXLo {
  &-content {
    border-radius: 10px !important;
    background: $accent !important;
    border: 0.1rem solid $primary !important;

    p {
      @include heading-4;

      font-weight: bold;
      color: $dark;
      margin-bottom: 20px;
    }

    label {
      @include heading-4;

      font-weight: bold;
      color: $dark;
    }

    input {
      padding: 1.6rem !important;
      color: $primary !important;
      background-color: $dark !important;

      &::placeholder {
        color: $primary-light !important;
      }
    }
  }
}

/* stylelint-disable-next-line selector-id-pattern */
#PGEYpBmXLo-next-to-page2_schedule-button {
  width: 100% !important;

  @include heading-3;
}
