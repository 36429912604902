@mixin gradient-color {
  background-image: linear-gradient(180deg, $secondary-dark 0%, $accent-light 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -moz-text-fill-color: transparent;
}

@mixin gradient-color-light {
  background-image: linear-gradient(180deg, $secondary-light 0%, $accent-light 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -moz-text-fill-color: transparent;
}

@mixin mq-medium {
  // @media (min-width: 900px) { @content; }
  @media (min-width: 1000px) { @content; }
}

@mixin heading-0 {
  font-family: Roboto;
  font-size: 7.2rem;
  line-height: 7.6rem;
}

@mixin heading-1 {
  font-family: Roboto;
  font-size: 4.8rem;
  line-height: 5.2rem;
}

@mixin heading-2 {
  font-family: Roboto;
  font-size: 3.2rem;
  line-height: 3.6rem;
}

@mixin heading-3 {
  font-family: Roboto;
  font-size: 2.4rem;
  line-height: 2.8rem;
}

@mixin heading-4 {
  font-family: Roboto;
  font-size: 1.8rem;
  line-height: 2.2rem;
}

@mixin heading-5 {
  font-family: Roboto;
  font-size: 1.6rem;
  line-height: 2rem;
}

@mixin body {
  font-family: Roboto;
  font-size: 1.6rem;
}
