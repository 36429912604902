
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.footer {
  border-top: 0.1rem solid rgba($accent-light, 0.1);
  color: $primary-dark;
  padding: 0 0.8rem;
  margin-top: 8rem;
  background: $dark-dark;

  @include mq-medium {
    padding: 0 1.6rem;
    margin-top: 12rem;
  }

  &__inner {
    max-width: 120rem;
    margin: 0 auto;
  }

  @include heading-3;

  &__top {
    padding-top: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq-medium {
      flex-direction: row;
    }

    justify-content: space-between;
  }

  &__top-left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo-and-name {
    display: flex;
    align-items: center;
  }

  &__logo {
    height: 6.4rem;
    filter: brightness(50%);
    margin-right: 1.6rem;
  }

  &__viladeri {
    @include heading-3;

    font-weight: bold;
    white-space: nowrap;
  }

  &__motto {
    @include heading-4;

    margin-top: 2.4rem;
  }

  &__social-media {
    display: flex;
    align-items: center;
    margin-top: 3.2rem;
  }

  &__social-media-link {
    &:not(:last-child) {
      margin-right: 1.6rem;
    }
  }

  &__social-media-icon {
    fill: $primary-dark;
    transition: fill 0.5s, transform 0.5s;

    &:hover {
      fill: $accent-light;
      transform: scale(1.2);
    }
  }

  &__main-links {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 3.2rem;

    @include mq-medium {
      margin-top: 0;
    }
  }

  &__side-links {
    margin-top: 3.2rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include mq-medium {
      margin-top: 0;
    }
  }

  &__link {
    color: $primary-dark;
    text-decoration: none;
    transition: fill 0.5s, transform 0.5s;

    &:not(:last-child) {
      margin-bottom: 3.2rem;
    }

    &:hover {
      color: $accent-light;
      transform: scale(1.2);
    }
  }

  &__copyright {
    @include body;

    margin-top: 3.2rem;
    padding: 1.6rem 0;
    text-align: center;
    border-top: 0.1rem solid rgba($accent-light, 0.1);
  }

  &__img {
    width: 20rem;

    &--anpc {
      margin-bottom: 0.8rem;
    }
  }
}
