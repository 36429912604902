
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.about-us {
  margin-top: 6rem;

  @include mq-medium {
    margin-top: 12rem;
  }

  &__title {
    @include gradient-color;
    @include heading-2;

    font-weight: bold;
    text-align: center;

    @include mq-medium {
      @include heading-0;
    }
  }

  &__name {
    @include gradient-color;
    @include heading-3;

    margin-top: 4rem;
    margin-bottom: 1.6rem;

    @include mq-medium {
      @include heading-2;

      margin-top: 8rem;
    }
  }
}
