
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.header {
  background-color: $dark-dark;
  height: 4.8rem;
  display: flex;
  align-items: center;
  padding: 1.6rem 0.8rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 0.1rem solid rgba($accent-light, 0.1);
  z-index: 1000;

  @include mq-medium {
    height: 16rem;
    padding: 1.6rem;
  }

  &__logo {
    height: 3.2rem;
    cursor: pointer;

    @include mq-medium {
      height: 6.4rem;
    }
  }

  &__viladeri {
    font-weight: bold;
    margin-left: 0.8rem;
    cursor: pointer;

    @include heading-5;
    @include gradient-color;

    @include mq-medium {
      @include heading-2;
    }
  }

  &__right {
    display: none;
    margin-left: auto;
    align-self: stretch;
    align-items: flex-end;

    @include mq-medium {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__right-bottom {
    display: flex;
  }

  &__link {
    @include heading-4;
    @include gradient-color;

    position: relative;
    text-transform: uppercase;
    font-weight: bold;
    transition: text-shadow 0.5s;

    &:not(:last-child) {
      margin-right: 1.6rem;
    }

    &:hover {
      text-shadow: 0 0 10px rgb($accent-light, 0.75);
    }
  }

  &__burger-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: auto;

    @include mq-medium {
      display: none;
    }
  }

  &__burger {
    width: 2rem;
    height: 0.2rem;
    background: $accent;
    border-radius: 0.5rem;
    position: relative;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    z-index: 10;

    &::before,
    &::after {
      left: 0;
      top: 0;
      content: "";
      position: absolute;
      width: 2rem;
      height: 0.2rem;
      background: $accent;
      border-radius: 0.5rem;
      transition: all 0.3s ease-in-out;
      pointer-events: initial;
    }

    &::before {
      transform: translateY(-0.8rem);
    }

    &::after {
      transform: translateY(0.8rem);
    }

    &--active {
      transform: translateX(-5rem);
      background: transparent;
      box-shadow: none;

      &::before {
        transform: rotate(45deg) translate(3.5rem, -3.5rem);
      }

      &::after {
        transform: rotate(-45deg) translate(3.5rem, 3.5rem);
      }
    }
  }

  &__menu-background {
    position: fixed;
    width: 2rem;
    pointer-events: none;
    height: 2rem;
    background: $dark-dark;
    border-radius: 50%;
    transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
    z-index: 10;

    &--active {
      transform: scale(150);
    }
  }

  &__menu {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(-100%, -50%);
    z-index: 10;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    align-items: center;
    pointer-events: none;

    &--active {
      transform: translate(0, -50%);
    }
  }

  &__menu-link {
    @include heading-4;
    @include gradient-color;

    pointer-events: initial;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
  }
}
