
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.language-picker {
  background: $primary-dark;
  border-radius: 0.8rem;
  pointer-events: initial;

  &__language {
    padding: 0.8rem;
    transition: all 0.5s;
    display: inline-block;
    cursor: pointer;

    &:hover {
      background: $dark-dark;
    }

    &--active {
      background: $dark-dark;
    }

    span {
      @include gradient-color;

      font-weight: bold;
    }
  }
}
