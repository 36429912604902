
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.homepage {
  &__slider-page {
    position: relative;
  }

  &__image {
    height: 80vh;

    @include mq-medium {
      height: 70vh;
    }

    width: 100%;
    object-fit: cover;

    &--right {
      object-position: 80% 50%;
    }
  }

  &__overlay-text {
    position: absolute;
    top: 6rem;
    left: 1.6rem;
    background: rgba($dark, 0.6);
    padding: 1.6rem 0.8rem;
    color: $accent;
    border-radius: 0.8rem;
    cursor: pointer;

    @include body;

    @include mq-medium {
      @include heading-2;
    }
  }
}

.slick-dots > * {
  background: $secondary-dark;
  border-radius: 30%;
  cursor: pointer;
}
