
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.motto {
  margin-top: 6rem;
  position: relative;

  @include mq-medium {
    margin-top: 12rem;
  }

  &__main-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
  }

  &__wrapper {
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 0.8rem;

    @include mq-medium {
      padding: 0 1.6rem;
    }
  }

  &__background {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (min-width: 600px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @include mq-medium {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  &__picture {
    &--hide {
      @media (min-width: 600px) {
        display: none;
      }

      @include mq-medium {
        display: initial;
      }
    }
  }

  &__img {
    opacity: 0.1;
    width: 100%;
    height: 28rem;
    object-fit: cover;
    object-position: top;

    @media (min-width: 350px) {
      height: 25rem;
    }

    @media (min-width: 450px) {
      height: 20rem;
    }

    @media (min-width: 600px) { height: 35rem; }

    @media (min-width: 800px) { height: 30rem; }

    @include mq-medium {
      height: 40rem;
    }
  }

  &__header {
    @include gradient-color;
    @include heading-2;

    font-style: italic;
    font-weight: bold;
    text-align: center;
    transform: scale(0.2);

    @include mq-medium {
      @include heading-0;
    }

    transition: all 1s;

    &--visible {
      transform: scale(1);
    }
  }

  &__paragraphs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 6rem;
  }

  &__paragraph {
    color: $accent;
    margin-top: 1rem;
    text-indent: 2rem;
    text-align: center;

    &--left {
      transform: translateX(-100%);
    }

    &--right {
      transform: translateX(100%);
    }

    opacity: 0;
    filter: blur(0.5rem);
    transition: all 1s;

    &--visible {
      opacity: 1;
      filter: blur(0);
      transform: translateX(0) !important;
    }

    @include heading-4;
  }
}
