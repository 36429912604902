
            @import "src/assets/scss/_variables.scss";
            @import "src/assets/scss/_mixins.scss";
          
.schedule {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background: $dark-dark;
  color: $accent;
  text-align: center;
  padding: 1.6rem;
  border-radius: 1rem;
  border: 0.1rem solid rgba($accent-light, 0.1);
  z-index: 3;
  cursor: pointer;

  @include heading-5;
}
